<template>
  <!-- 中标信息 -->
  <div>
    <div id="achievementCount" style="position: relative;height: 0;overflow: hidden;top: -120px;"></div>
    <block>
      <div class="table-list-title dis-bet-cen">
        <div>
          <div style="margin-bottom: 15px;">中标信息 <span style="color: #5D6FE9;">{{page.total + page.totalsub || 0}}</span></div>
          <div v-if="page.totalsub > 0">
            <span :class="active == 1 ? 'choose-active' : ''" @click="handleChange(1)" style="cursor: pointer;">总公司业绩 <span style="color: #5D6FE9;">{{page.total}}</span></span>
            <span :class="active == 2 ? 'choose-active' : ''" @click="handleChange(2)" style="margin-left: 30px;cursor: pointer;">分公司业绩 <span style="color: #5D6FE9;">{{page.totalsub}}</span></span>
          </div>
        </div>

        <el-select v-model="projectValue" placeholder="请选择" @change="handleProjectSelect" v-show="!fuzzyDataShow && !returnLimit">
          <el-option label="全部" value="9">全部</el-option>
          <el-option
            v-show="active == 1"
            v-for="(item, index) in projectOption"
            :key="index"
            :label="item.label"
            :value="index">
            <span style="float: left">{{ item.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.num }}</span>
          </el-option>
          <el-option
            v-show="active == 2"
            v-for="(item, index) in projectOptionSub"
            :key="index"
            :label="item.label"
            :value="index">
            <span style="float: left">{{ item.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.num }}</span>
          </el-option>
        </el-select>
      </div>
      <el-table
        border
        :data="TableData"
        :cell-style="cellStyle"
        :header-cell-style="headerColor"
        style="width: 100%;">
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column
            label="中标项目名称"
            width="550">
            <template #default="scope">
             <span style="color: #5D6FE9;cursor: pointer;" @click="handleTo(scope.row)">{{scope.row['projectName'] || '-'}}</span>
            </template>
          </el-table-column>
        <el-table-column v-for="(item,index) in tableHeader" :key="index" :label="item.label" :property="item.property" align="center">
          <template #default="scope">
            <span>{{scope.row[scope.column.property] || '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!returnLimit"
          label="操作"
          width="80">
          <template #default="scope">
           <el-button type="text" @click="handleTo(scope.row)">详情</el-button>
          </template>
        </el-table-column>
        <template #empty style="width: 100%;">
          <gaussian-blur-empty-login @loginEmit="loginEmit" v-show="!islogin"></gaussian-blur-empty-login>
          <gaussian-blur-empty-vip @loginEmit="loginEmit" v-show="fuzzyDataShow && returnLimit"></gaussian-blur-empty-vip>
          <gaussian-blur-empty-vip @loginEmit="loginEmit" v-show="!fuzzyDataShow && returnLimit && TableData.length == 0"></gaussian-blur-empty-vip>
          <span v-if="!fuzzyDataShow && !returnLimit && TableData.length == 0">暂无数据</span>
        </template>
      </el-table>
      <gaussian-blur-empty-vip @loginEmit="loginEmit" v-show="!fuzzyDataShow && returnLimit && TableData.length > 0"></gaussian-blur-empty-vip>
      <div class="table-page" v-show="!fuzzyDataShow && !returnLimit">
        <el-pagination
          background
          hide-on-single-page="true"
          :page-size="10"
          :pager-count="7"
          layout="prev, pager, next"
          v-model:currentPage="page.pageNum"
          @current-change="pageChange"
          :total="active == 1 ? page.total : page.totalsub">
        </el-pagination>
      </div>
    </block>
  </div>
</template>

<script>
// import { conversionNum } from '@/utils/index'
import { forwardBG, oldForwardBG } from '@/api/forward.js'
import GaussianBlurEmptyLogin from '@/components/gaussian-blur/gaussian-blur-empty-login'
import GaussianBlurEmptyVip from '@/components/gaussian-blur/gaussian-blur-empty-vip'
import { mapGetters } from 'vuex'
export default {
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'company')
    }
    return {
      loginEmit
    }
  },
  name: 'achievement-count',
  props: { companyId: String, num: Number, to: Number },
  components: { GaussianBlurEmptyLogin, GaussianBlurEmptyVip },
  data () {
    return {
      active: 1,
      page: {
        total: this.num ? this.num : 0,
        totalsub: 0,
        pageNum: 1
      },
      // 股东信息
      tableHeader: [{
        label: '中标金额（万元）',
        property: 'money'
      }, {
        label: '中标时间',
        property: 'timeSuccess'
      }, {
        label: '所属省市',
        property: 'provinces'
      }],
      TableData: [],
      TableDataSub: [],
      TableDataOld: [],
      projectOption: [],
      projectOptionSub: [],
      projectValue: '9',
      islogin: false,
      returnLimit: false,
      fuzzyDataShow: false
    }
  },
  computed: {
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo'
    ]),
    vipLevelChange: function () {
      const vm = this
      if (vm.vipData && JSON.parse(vm.vipData)) {
        return JSON.parse(vm.vipData).vipLevel
      } else {
        return null
      }
    }
  },
  mounted () {
    this.initProjectTime()
    this.initProjectTimeSub()
    if (this.to === 2) {
      document.querySelector('#achievementCount').scrollIntoView()
    }
    if (this.idToken) {
      this.islogin = true
      this.initData()
      this.initDataSub()
    } else {
      this.islogin = false
    }
  },
  watch: {
    idToken: {
      handler (val, oldval) {
        if (val) {
          if (this.idToken) {
            this.islogin = true
            this.initData()
          } else {
            this.islogin = false
          }
        }
      }
    },
    vipLevelChange: function (newValue, oldValue) {
      if (this.idToken) {
        this.islogin = true
        this.initData()
      } else {
        this.islogin = false
      }
    }
  },
  methods: {
    // 表格设置样式
    headerColor: function ({ row, column, rowIndex, columnIndex }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    cellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center'
    },
    handleTo: function (item) {
      const vm = this
      sessionStorage.removeItem('company-achievement-details-item')
      sessionStorage.setItem('company-achievement-details-item', JSON.stringify(item))
      const routeData = vm.$router.resolve({
        name: 'company-achievement-details'
      })
      window.open(routeData.href, '_blank')
    },
    initProjectTime: function () {
      const vm = this
      const date = new Date()
      const year = date.getFullYear()
      const data = {
        dateBackgroundUrl: '/companyBid/getCountByBid',
        dateBackgroundRequestType: 'get',
        data: {
          companyId: vm.companyId
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          console.log(res)
          const arr = []
          for (const i in res) {
            if (i === 'other') {
              const obj = {
                label: '其他',
                min: '',
                max: year - 4 + '-12-31 23:59:59',
                num: res[i]
              }
              arr.push(obj)
            } else {
              const obj = {
                label: i,
                min: i + '-01-01 00:00:00',
                max: i + '-12-31 23:59:59',
                num: res[i]
              }
              arr.unshift(obj)
            }
          }
          vm.projectOption = arr
        }
      }).catch(error => {
        console.log(error)
      })
    },
    initProjectTimeSub: function () {
      const vm = this
      // const date = new Date()
      // const year = date.getFullYear()
      const data = {
        dateBackgroundUrl: '/companyBid/getSubCompanyBidYear',
        dateBackgroundRequestType: 'get',
        data: {
          companyId: vm.companyId
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          for (let i = 0; i < res.length; i++) {
            const obj = {
              label: res[i].year,
              min: res[i].year + '-01-01 00:00:00',
              max: res[i].year + '-12-31 23:59:59',
              num: res[i].count
            }
            arr.unshift(obj)
          }
          vm.projectOptionSub = arr
        }
      }).catch(error => {
        console.log(error)
      })
    },
    handleProjectSelect: function (e) {
      this.page.pageNum = 1
      if (this.active === 1) {
        this.initData()
      } else {
        this.initDataSub()
      }
    },
    pageChange (e) {
      this.page.pageNum = e
      if (this.active === 1) {
        this.initData()
      } else {
        this.initDataSub()
      }
    },
    handleChange (e) {
      const vm = this
      vm.active = e
      vm.page.pageNum = 1
      vm.projectValue = '9'
      vm.initData()
      vm.initDataSub()
      vm.initProjectTimeSub()
    },
    initDataSub () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/companyBid/getSubCompanyAchievementList',
        dateBackgroundRequestType: 'get',
        data: {
          companyId: vm.companyId,
          pageNum: vm.page.pageNum,
          bidTimeFrom: vm.projectValue === '9' ? '' : vm.projectOptionSub[vm.projectValue].min,
          bidTimeTo: vm.projectValue === '9' ? '' : vm.projectOptionSub[vm.projectValue].max,
          pageSize: 10
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          vm.page.totalsub = res.total
          res.list.forEach(item => {
            item.timeSuccess = item.timeSuccess ? item.timeSuccess.substring(0, 10) : '-'
            item.provinces = item.province + item.city
            let n = item.money + ''
            n = '000000' + n
            const i = n.indexOf('.')
            if (i !== -1) {
              n = n.substring(0, i) + n.substring(i + 1, n.length)
              const j = n.substring(0, i - 4) + '.' + n.substring(i - 4, n.length)
              item.money = j * 1
            } else {
              item.money = item.money / 10000
            }
            // item.money = item.money ? (item.money * 10000) / 100000000 : '-'
            arr.push(item)
          })
          vm.TableDataSub = arr
          if (vm.active === 2) {
            vm.TableData = arr
          }
        }
      }).catch(error => {
        console.log(error)
      })
    },
    initData: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/companyBid/getBidList',
        dateBackgroundRequestType: 'get',
        data: {
          companyId: vm.companyId,
          pageNum: vm.page.pageNum,
          bidTimeFrom: vm.projectValue === '9' ? '' : vm.projectOption[vm.projectValue].min,
          bidTimeTo: vm.projectValue === '9' ? '' : vm.projectOption[vm.projectValue].max,
          pageSize: 10
        }
      }
      oldForwardBG(data).then(result => {
        if (result.code === 0 || result.code === 97 || result.code === 98) {
          if (result.code === 97) {
            vm.returnLimit = true
            vm.fuzzyDataShow = false
          } else if (result.code === 98) {
            vm.returnLimit = true
            vm.fuzzyDataShow = true
          } else {
            vm.returnLimit = false
            vm.fuzzyDataShow = false
          }
          const res = result.data
          const arr = []
          vm.page.total = res.total
          res.list.forEach(item => {
            item.timeSuccess = item.timeSuccess ? item.timeSuccess.substring(0, 10) : '-'
            item.provinces = item.province + item.city
            let n = item.money + ''
            n = '000000' + n
            const i = n.indexOf('.')
            if (i !== -1) {
              n = n.substring(0, i) + n.substring(i + 1, n.length)
              const j = n.substring(0, i - 4) + '.' + n.substring(i - 4, n.length)
              item.money = j * 1
            } else {
              item.money = item.money / 10000
            }
            // item.money = item.money ? (item.money * 10000) / 100000000 : '-'
            arr.push(item)
          })
          vm.TableDataOld = arr
          if (vm.active === 1) {
            vm.TableData = arr
          }
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.table-list-title {
  padding: 18px 0;
  text-align: left;
  color: #333;
  font-weight: bold;
}
.table-page {
  text-align: right;
  margin-top: 10px;
}
/deep/ .el-table__empty-text {
  width: 100%;
}
.choose-active {
  color: #5D6FE9;
  position: relative;
}
.choose-active::after {
  content: '';
  height: 2px;
  width: 80px;
  background-color: #5D6FE9;
  position: absolute;
  top: 25px;
  left: 50%;
  margin-left: -40px;
}
</style>
